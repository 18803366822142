import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { accordionItem, accordionModule } from './index.module.scss'

const AccordionItem = props => {
  return (
    <div className={`${accordionItem} ${props.active ? 'active' : ''}`} onClick={props.onClick}>
      <h5 className="headline">{props.item.title}</h5>
      <div className="content">{props.item.body}</div>
    </div>
  )
}

const Accordion = props => {
  const [activeItem, setActiveItem] = useState()

  return (
    <div className={accordionModule}>
      <div className="inner-wrapper">
        <div className="accordion-wrapper">
          {props.content ? props.content.map((item, index) => <AccordionItem active={index === activeItem} item={item} key={index} onClick={() => setActiveItem(index)} />) : 'Kein Inhalt'}
        </div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  content: PropTypes.array,
}

// Accordion.defaultProps = {
//   siteTitle: ``,
// }

export default Accordion
