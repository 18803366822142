import React, { useState } from 'react'
import { Link } from 'gatsby'

import MainContainer from '../container/main'
import ContentHeroNew from '../components/content-hero-new'
import Subheadline from '../components/content-subheadline'
import Accordion from '../components/accordion'
import SEO from '../components/seo'
import { pricingContainer } from './pricing.module.scss'
import Container from '../components/container'
import Contact from '../components/contact'

const contentFAQ = [
  {
    title: 'Can different plans be combined?',
    body: 'No, this is technically not possible. If you switch to a chargeable plan, this plan applies to all users.',
  },
  {
    title: 'Can I change my plan?',
    body: 'Of course, you can change your plan at any time. Note that changes are made to all users of your team.',
  },
  {
    title: 'What are my payment options?',
    body: 'You can pay by Credit Card. Payments are processed by a leading payment service provider. Basaas does not store any payment data.',
  },
  {
    title: 'I need to add new users to my team. How does that affect my plan?',
    body: 'You can easily add or delete users to your organization in your tenant settings. These changes will be automatically made to your plan.',
  },
  {
    title: 'What are the billing period options?',
    body: 'All subscriptions are billed monthly.',
  },
]

const PricingPage = ({ data }) => {
  const [activeTier, setActiveTier] = useState('1 - 99')
  const [activeCurrency, setActiveCurrency] = useState('USD')
  const currencyShort = activeCurrency === 'USD' ? '$' : '€'

  const plans = {
    '1 - 99': {
      smart: {
        USD: '6.50',
        EUR: '5,50',
      },

      professional: {
        USD: '9.50',
        EUR: '8,50',
      },
    },
    '100 - 249': {
      smart: {
        USD: '6.00',
        EUR: '5,00',
      },

      professional: {
        USD: '8.50',
        EUR: '7,50',
      },
    },
    '250 - 499': {
      smart: {
        USD: '5.50',
        EUR: '4,50',
      },

      professional: {
        USD: '8.00',
        EUR: '7,00',
      },
    },
    '500 - 999': {
      smart: {
        USD: '5.00',
        EUR: '4,00',
      },

      professional: {
        USD: '7.00',
        EUR: '6,00',
      },
    },
    '1000+': {
      smart: {
        USD: '4.50',
        EUR: '3,50',
      },

      professional: {
        USD: '6.00',
        EUR: '5,00',
      },
    },
  }

  return (
    <MainContainer fixedWidth>
      <SEO title="Pricing" />
      <Container>
        <ContentHeroNew headline="Choose the plan that suits you best" description="Try Basaas free of charge by any number of colleagues and upgrade when you need it" />
        <div className={pricingContainer}>
          <div className="bas__pricing_meta">
            <div className="item">
              <h3 className="headline">How many users you got?</h3>
              <ul className="list bas__pricing_tiers">
                <li className={`bas__pricing_tier-btn ${activeTier === '1 - 99' ? 'active' : ''}`} onClick={() => setActiveTier('1 - 99')} data-tier="1 - 99">
                  1 - 99
                </li>
                <li className={`bas__pricing_tier-btn ${activeTier === '100 - 249' ? 'active' : ''}`} onClick={() => setActiveTier('100 - 249')} data-tier="100 - 249">
                  100 - 249
                </li>
                <li className={`bas__pricing_tier-btn ${activeTier === '250 - 499' ? 'active' : ''}`} onClick={() => setActiveTier('250 - 499')} data-tier="250 - 499">
                  250 - 499
                </li>
                <li className={`bas__pricing_tier-btn ${activeTier === '500 - 999' ? 'active' : ''}`} onClick={() => setActiveTier('500 - 999')} data-tier="500 - 999">
                  500 - 999
                </li>
                <li className={`bas__pricing_tier-btn ${activeTier === '1000+' ? 'active' : ''}`} onClick={() => setActiveTier('1000+')} data-tier="1000+">
                  1000+
                </li>
              </ul>
            </div>

            <div className="item">
              <h3 className="headline">Currency</h3>
              <ul className="list bas__pricing_currencies">
                <li className={`bas__pricing_currency-btn ${activeCurrency === 'USD' ? 'active' : ''}`} onClick={() => setActiveCurrency('USD')} data-currency="USD" data-currency-short="$">
                  $ USD
                </li>
                <li className={`bas__pricing_currency-btn ${activeCurrency === 'EUR' ? 'active' : ''}`} onClick={() => setActiveCurrency('EUR')} data-currency="EUR" data-currency-short="€">
                  € EUR
                </li>
              </ul>
            </div>
          </div>

          <ul className="bas__pricing_plans_container">
            <li className="bas__pricing_plan highlight">
              <span className="title">Free</span>
              <span className="price">
                <span className="bas__plan-value">0</span> <span className="bas__plan-currency-type">{currencyShort}</span>
              </span>
              <span className="billing-period"></span>
              <span className="description">For teams using a few apps or just trying basaas out</span>

              <span className="feature-headline">Features</span>
              <ul className="features-list">
                <li>Unlimited apps in Workplace</li>
                <li>3 integrations per user</li>
                <li>1 administrator</li>
                <li>10 corporate apps</li>
                <li>3 user groups</li>
                <li>3 team projects</li>
                <li>Dashboard & My Day</li>
                <li>Password management</li>
                <li>Task management</li>
              </ul>
              <a
                href="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=table&utm_campaign=signup&utm_content=pricing"
                target="_blank"
                className="action"
                rel="noopener noreferrer"
              >
                Get started
              </a>
            </li>

            <li className="bas__pricing_plan">
              <span className="title">Smart</span>
              <span className="price bas__plan-currency-val bas__plan-smart">
                <span className="bas__plan-value">{plans[`${activeTier}`].smart[activeCurrency]}</span>
                <span className="bas__plan-currency-type">{currencyShort}</span>
              </span>
              <span className="billing-period">per user per month</span>
              <span className="description">For small or medium-sized comanies with flat hierarchies</span>

              <span className="feature-headline">All of Free plus:</span>
              <ul className="features-list">
                <li>10 integrations per user</li>
                <li>3 administrators</li>
                <li>50 corporate apps</li>
                <li>10 user groups</li>
                <li>50 team projects</li>
                <li>Workplace Templates</li>
                <li>Priority email support</li>
              </ul>
              <Link className="action contact" to="/contact">
                Contact us
              </Link>
            </li>

            <li className="bas__pricing_plan">
              <span className="title">Professional</span>
              <span className="price bas__plan-currency-val bas__plan-professional">
                <span className="bas__plan-value">{plans[`${activeTier}`].professional[activeCurrency]}</span> <span className="bas__plan-currency-type">{currencyShort}</span>
              </span>
              <span className="billing-period">per user per month</span>

              <span className="description">For well-managed companies with organizational structure</span>

              <span className="feature-headline">All of Smart plus:</span>
              <ul className="features-list">
                <li>25 integrations per user</li>
                <li>Unlimited administrators</li>
                <li>Unlimited corporate apps</li>
                <li>Unlimited user groups</li>
                <li>Unlimited team projects</li>
                {/* <li>Azure AD integration</li> */}
                <li>Usage analytics</li>
                <li>Professional support</li>
              </ul>
              <Link className="action contact" to="/contact">
                Contact us
              </Link>
            </li>

            <li className="bas__pricing_plan enterprise">
              <span className="title">Enterprise</span>
              <span className="price">Let’s talk!</span>
              <span className="billing-period"></span>
              <span className="description">For large companies (1.000+ user) with individual needs</span>

              <span className="feature-headline">All of Professional plus:</span>
              <ul className="features-list">
                <li>Unlimited integrations</li>
                <li>Tailored pricing offer</li>
                <li>Multi region deployment</li>
                <li>Dedicated instance</li>
                <li>Dedicated account manager</li>
                {/* <li>Enterprise security</li> */}
                <li>24/7 support</li>
              </ul>
              <Link className="action contact" to="/demo">
                More information
              </Link>
            </li>
          </ul>

          <Subheadline headline="Feature comparison" />

          <div className="feature-comparison feature-comparison--5cols feature-comparison--collapse">
            <div className="feature-item feature-name bas__comparison_head"></div>
            <div className="feature-item bas__comparison_head">Free</div>
            <div className="feature-item bas__comparison_head">Smart</div>
            <div className="feature-item bas__comparison_head">Professional</div>
            <div className="feature-item bas__comparison_head">Enterprise</div>
            <div className="feature-item feature-name group-headline" style={{ paddingTop: '12px' }}>
              Basaas Workplace
            </div>
            <div className="feature-item group-headline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-headline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-headline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-headline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item feature-name group-subheadline" style={{ paddingTop: '12px' }}>
              For user
            </div>
            <div className="feature-item group-subheadline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-subheadline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-subheadline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item group-subheadline" style={{ paddingTop: '12px' }}></div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Apps on workplace
                <span className="info">An app is a service you use – like Gmail or Microsoft Teams. You can add as many of them as you want to get the most out of your workplace.</span>
              </h3>
            </div>
            <div className="feature-item free">unlimited</div>
            <div className="feature-item smart">unlimited</div>
            <div className="feature-item professional">unlimited</div>
            <div className="feature-item enterprise">unlimited</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                App integrations per user
                <span className="info">
                  An app integration is the connection between your service (e.g. Asana as a task app) and Basaas. It allows you to view data from your account directly in the widgets on the
                  workplace.
                </span>
              </h3>
            </div>
            <div className="feature-item free">3</div>
            <div className="feature-item smart">10</div>
            <div className="feature-item professional">25</div>
            <div className="feature-item enterprise">unlimited</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Widgets (Tasks, Calendar, Contacts)
                <span className="info">
                  Widgets can show you information from different apps in one place. Imagine you are using two or more calendars. This widget will show all your appointments from all calendars.
                </span>
              </h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Dashboard & My Day
                <span className="info">
                  The Dashboard contains all your apps you use on a daily basis and shows you information from your integrated apps with its widgets. My Day gives you a unified view on your daily
                  events and tasks.
                </span>
              </h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Passwort Management
                <span className="info">
                  You can store and organize your passwords with our password manager. But it also helps you to log in to your different apps within the Basaas app. You passwords are stored highly
                  encrypted and secured on your machine.
                </span>
              </h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Chrome Extension
                <span className="info">
                  The Chrome Browser Extension gives you the My Day view in every new tab, a popup where you can access and search all your apps and the omnipresent sidebar across the web.
                </span>
              </h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name group-subheadline">For manager</div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item feature-name">
              <h3>Administrators</h3>
            </div>
            <div className="feature-item free">1</div>
            <div className="feature-item smart">3</div>
            <div className="feature-item professional">unlimited</div>
            <div className="feature-item enterprise">unlimited</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Centrally managed (corporate) apps
                <span className="info">Corporate apps are apps that are available companywide and managed by the administrator. Every change is distributed to all users in real time.</span>
              </h3>
            </div>
            <div className="feature-item free">10</div>
            <div className="feature-item smart">50</div>
            <div className="feature-item professional">unlimited</div>
            <div className="feature-item enterprise">unlimited</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                User groups for central team management
                <span className="info">
                  User groups help you to organize apps and users in Basaas. For example, you can create a group "Marketing" and add all marketing team members. You can also assign your own apps to
                  these groups. So every new employee has all the important apps in his or her workplace.
                </span>
              </h3>
            </div>
            <div className="feature-item free">3</div>
            <div className="feature-item smart">10</div>
            <div className="feature-item professional">unlimited</div>
            <div className="feature-item enterprise">unlimited</div>
            <div className="feature-item feature-name">
              <h3 className="coming-soon">2-Factor-Authentication</h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Workplace Templates
                <span className="info">
                  Predefine a workplace layout, add apps and widgets on the workplace assign users to it. For example new users will have instant access to all important apps to start right away.
                </span>
              </h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info">
                Analytics
                <span className="info">See fully anonymized how active your users have been and which apps they have used. This way you can get a perfect overview and reduce shadow-it.</span>
              </h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">-</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info coming-soon">
                Azure AD Integration
                <span className="info">Import your users and groups and keep your Active Directory in sync with your Basaas. That reduces the effort for your admins dramatically.</span>
              </h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">-</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3 className="with-info coming-soon">
                User Permissions
                <span className="info">Restrict user access to specific resources like location, device or time of the day.</span>
              </h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">-</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name group-headline">Basaas Tasks</div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item feature-name group-subheadline">For User</div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item feature-name">
              <h3>Unified tasks from integrated apps</h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3>Create tasks everywhere</h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3>Assigned tasks to colleagues</h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name group-subheadline">For Manager</div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item group-subheadline"></div>
            <div className="feature-item feature-name">
              <h3>Projects for task management</h3>
            </div>
            <div className="feature-item free">3</div>
            <div className="feature-item smart">50</div>
            <div className="feature-item professional">unlimited</div>
            <div className="feature-item enterprise">unlimited</div>

            <div className="feature-item feature-name group-headline">Get Support</div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item group-headline"></div>
            <div className="feature-item feature-name">
              <h3>Online Self-Service</h3>
            </div>
            <div className="feature-item free">✔</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3>Priority Email Support</h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">✔</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
            <div className="feature-item feature-name">
              <h3>Professional Support</h3>
            </div>
            <div className="feature-item free">-</div>
            <div className="feature-item smart">-</div>
            <div className="feature-item professional">✔</div>
            <div className="feature-item enterprise">✔</div>
          </div>

          <Container className="pricing-content-block">
            <Subheadline headline="Frequently asked questions" />

            <Accordion content={contentFAQ} />
          </Container>
          <Container className="pricing-content-block">
            <Subheadline headline="Need assistance? Get in touch!" />

            <Contact />
          </Container>
        </div>
      </Container>
    </MainContainer>
  )
}

export default PricingPage
