// extracted by mini-css-extract-plugin
export const pricingContainer = "pricing-module__pricingContainer--T3fh_";
export const pricingContentBlock = "pricing-content-block";
export const basPricingMeta = "bas__pricing_meta";
export const item = "item";
export const headline = "headline";
export const list = "list";
export const active = "active";
export const basPricingPlansContainer = "bas__pricing_plans_container";
export const basPricingPlan = "bas__pricing_plan";
export const highlight = "highlight";
export const enterprise = "enterprise";
export const action = "action";
export const contact = "contact";
export const title = "title";
export const price = "price";
export const description = "description";
export const billingPeriod = "billing-period";
export const featureHeadline = "feature-headline";
export const featuresList = "features-list";
export const priceContact = "price-contact";
export const featureComparison = "feature-comparison";
export const featureItem = "feature-item";
export const featureComparison5Cols = "feature-comparison--5cols";
export const featureName = "feature-name";
export const basComparisonHead = "bas__comparison_head";
export const groupHeadline = "group-headline";
export const groupSubheadline = "group-subheadline";
export const comingSoon = "coming-soon";
export const withInfo = "with-info";
export const info = "info";
export const featureComparisonCollapse = "feature-comparison--collapse";
export const free = "free";
export const smart = "smart";
export const professional = "professional";
export const noFlexbox = "no-flexbox";